import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Platform, ToastController, AlertController, LoadingController } from '@ionic/angular';
import { InAppReview } from '@ionic-native/in-app-review/ngx';
import { askrating,askRatingdays, Apptype } from '../../config';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class AppRatingService {


  private askRating
  private askRatingdays;
  appType: string;
  androidLink: string;

  setAndroidLink(androidLink) {
    this.androidLink = androidLink;
  }
  
  constructor(public http: Http,
    private alertCtrl: AlertController,
    public platform: Platform,
    private inAppReview: InAppReview,
    private storage: Storage,
    private translateService : TranslateService,
    private iab: InAppBrowser,
  
    ) {
      this.appType = Apptype;
    }

  async getrating()
  {

    this.askRating=askrating;
    this.askRatingdays=askRatingdays;
    this.translateService.get("RATINTXT").subscribe(val=>{
      this.checkDays().then(check=>{     //returns true if current date is after 30 days of stored date in storage 
        console.log(check,'checkcondition')
      
  if(this.askRating && check){
      this.saveDate();
      if(this.platform.is("android")){
        this.AndroidRating(val);
      }
      else if(this.platform.is("ios") ){
          this.IosRating(val)
      }

    }
      //  this.gotoAlert(val)
  })
})
   
  }


  forceRatingCall(){
    console.log('forcerating')
    if(askrating ){
    this.saveDate();
    this.translateService.get("RATINTXT").subscribe(val=>{
      if(this.platform.is("android")){
        this.AndroidRating(val);
      }
      else if(this.platform.is("ios") ){
          this.IosRating(val)
      }
    });
  }
  }
 async AndroidRating(msg){
  console.log('forcerating3')

   let rateNowText, rateLaterText, enjoyFTText, enjoyAppText;
  this.translateService.get("RATENOW").subscribe(val=>{
    rateNowText = val
})

this.translateService.get("RATELATER").subscribe(val=>{
  rateLaterText = val
})

this.translateService.get("ENJOYINGFT").subscribe(val=>{
  enjoyFTText = val;
})

this.translateService.get("ENJOYINGAPP").subscribe(val=>{
  enjoyAppText = val
})

    const alert = await this.alertCtrl.create({
      header : this.appType == 'leejam' ? enjoyFTText : enjoyAppText,
      message: msg,
      buttons: [
        {
          text: rateLaterText,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: rateNowText,
          handler: () => {
            if(this.platform.is("android"))
            {
              // window.open(this.androidLink, '_system');
              this.iab.create(this.androidLink, '_system');
            }
            else
            if(this.platform.is("ios"))
            {

              // Handle iOS
            }
          }
        }
      ]
    });

    await alert.present();
  }

  checkDays() {
    let promise = new Promise((resolve, reject) => {
   this.storage.get('ratingDays').then((val)=>{
 if(val){
 let timestored=  moment(val,'DD MM YYYY').add(30, 'days');
    resolve(moment().isAfter(moment(timestored), 'days'));
}
else{
  resolve(true)
}
   });
  });
    return promise;

  }

  saveDate(){
   let DatetoShow= moment().format('DD MM YYYY');
    this.storage.set('ratingDays',DatetoShow);
  }



  IosRating(msg){
    this.inAppReview.requestReview()
  .then((res: any) => console.log(res))
  .catch((error: any) => console.error(error));


  }

}