import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { LogoutService } from './logout.service';
import { Device } from '@ionic-native/device/ngx';
import { HttpService } from './services/http/http.service';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AppRatingService } from './services/app-rating/app-rating.service';
import { Apptype } from './config';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class UpdateAppService {

  public StorageLoaded;
  isAlertOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  stortype: boolean;
  backButtonSubscription;
  androidLink: string;
  iosLink: string;
  appType: string;

  constructor(
    public translateService: TranslateService,
    private platform: Platform,
    public toastController: ToastController,
    public alertController: AlertController,
    public device: Device,
    private HTPS: HttpService,
    private storage: Storage,
    private appVersion: AppVersion,
    private appRating: AppRatingService,
    private iab: InAppBrowser
  ) {
    this.appType = Apptype;
  }

  ionViewWillEnter() {
    this.backButtonSubscription = this.platform.backButton.subscribe(async () => {
      navigator['app'].exitApp();
    });
  }

  ionViewDidLeave() {
    this.backButtonSubscription.unsubscribe();
  }

  async updateApp() {
    this.appVersion.getVersionNumber().then(version => {
      let appversion = version;
      console.log( appversion ," appversion");

      let appDetails = {
        'currentVersion': appversion,
        'deviceType': this.device.platform ? this.device.platform :'android',
        'clientId': "B2C"
      }
      this.HTPS.Updatedevices(appDetails)
        .subscribe((response) => {
          if (response) {
            this.androidLink = response['data']['androidAppLink'];
            this.appRating.setAndroidLink(this.androidLink);
            this.iosLink = response['data']['iOSAppLink'];
            if (response['data'].updateType == 'Forceful') {
              this.forceUpdateAlert();
            }
            else if (response['data'].updateType == 'Manual') {
              this.manualUpdateAlert();
            }
            else if (response['data'].updateType == "") {
              return;
            }
          }
        })
    });
  }

  async forceUpdateAlert() {

    let UpdateNow;
    let Later;
    let UpdateHeader;
    let UpdateContent;
    let updateMsg;
    console.log('LOGS FOUND')
    this.translateService.get('UPDATEAVAILABLE').subscribe(
      value => {
        UpdateHeader = value;
      });
    this.translateService.get('UPDATECONTENT').subscribe(
      value => {
        UpdateContent = value;
      });
    this.translateService.get('FORCEUDPATENOW').subscribe(
      value => {
        UpdateNow = value;
      });
    this.translateService.get('CLOSE').subscribe( 
      value => {
        Later = value;
      });
      this.translateService.get('UPDATEMSG').subscribe(
        value => {
          updateMsg = value;
      });

      if (this.platform.is('android')){
        const alert = await this.alertController.create({
          header: UpdateHeader,
          message: updateMsg,
          buttons: [
            {
              text: UpdateNow,
              // cssClass: 'secondary',
              handler: () => {
                alert.dismiss();
                this.gotoappstore();
              }
            }, {
              text: Later,
              handler: () => {
                this.isAlertOpen.next(false);
                navigator['app'].exitApp();
              }
            }
          ],
          backdropDismiss: false,
        });
        await alert.present();
      }
      else if (this.platform.is('ios')){
        const alert = await this.alertController.create({
          header: UpdateHeader,
          message: updateMsg,
          buttons: [
            {
              text: UpdateNow,
              // cssClass: 'secondary',
              handler: () => {
                alert.dismiss();
                this.gotoappstore();
              }
            }
          ],
          backdropDismiss: false,
        });
        await alert.present();
      }
  }
 
  async manualUpdateAlert() {
    let UpdateNow;
    let Later;
    let UpdateHeader;
    let UpdateContent;
    let updatemage;
    console.log('LOGS FOUND')
    this.translateService.get('Update available').subscribe(
      value => {
        UpdateHeader = value;

      });
    this.translateService.get('UPDATECONTENT').subscribe(
      value => {
        UpdateContent = value;
      });
    this.translateService.get('UPDATENOW').subscribe(
      value => {
        UpdateNow = value;
      });
    this.translateService.get('LATER').subscribe(
      value => {
        Later = value;
      });
      this.translateService.get('updatemage').subscribe(
        value => {
          updatemage = value;
        });


    const alert = await this.alertController.create({

      // cssClass: 'my-custom-class',
      header: UpdateHeader,
      message:updatemage ,
      buttons: [
        {
          text: UpdateNow,
          // cssClass: 'secondary',
          handler: () => this.gotoappstore()

        }, {
          text: Later,
          handler: () => {
          this.storage.set('isManualUpAlertClosed', true).then(val => console.log(val));
            document.addEventListener("backbutton", function (e) {
              console.log("disable back button")
            }, false);
            this.backButtonSubscription.unsubscribe();

          }
        }
      ],
      backdropDismiss: false
    }
    );

    await alert.present();

  }

  gotoappstore() {
    if (this.platform.is('android')) {
      if ((this.device.manufacturer.toLowerCase().includes("huawei") || this.device.manufacturer.toLowerCase().includes("honor")) && this.appType == 'leejam') {
        // window.open("https://appgallery1.huawei.com/#/app/C102026649");
        this.iab.create("https://appgallery1.huawei.com/#/app/C102026649",'_system');
      } else {
        // window.open(this.androidLink, "_system");  
        this.iab.create(this.androidLink, "_system");
      }
    }
    if (this.platform.is('ios')) {
      // iOS
      // window.open(this.iosLink, "_system");
      this.iab.create(this.iosLink, "_system");
    }
    // navigator['app'].exitApp();

    // this.logout.clearData()
    // navigator['app'].exitApp();

  }

  compareVersions(now, latest) {
    if (latest) {
      if (now == latest) {
        return false
      }
      else {
        let a = latest.split(".")
        let b = now.split(".")
        if (a.length == b.length) {
          let len = a.length
          for (let i = 0; i < len; i++) {
            if (a[i] > b[i]) {
              return true;
            }
            else
              if (a[i] < b[i]) {
                return false;
              }

          }

        }
      }
    }
    else {
      return false
    }

  }
}


