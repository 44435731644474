import { Injectable } from '@angular/core';
import { LoadingController, Events, Platform } from "@ionic/angular";
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import * as uuid from 'uuid';
import { Router } from '@angular/router';
import {LogoutService} from'../../logout.service'
import { LanguageService } from '../language/language.service';
import { identityURL } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  public idtt = '';
  public authurl;
  public tokenurl;
  public browserOption = "";

  public IsoCode;
  tocken: any;
  timestamp: string;
  constructor(public platform: Platform,
    public languageService: LanguageService,
    public events: Events,
    public http: Http,
    private iab: InAppBrowser,
    private storage: Storage,
    public loadingCtrl: LoadingController,
    private router: Router,
    private logout :LogoutService) {
    platform.ready().then(() => {

      if (this.platform.is('android')) {
        this.browserOption = "clearcache=yes;clearsessioncache=yes,location=no,zoom=no";
      }
      if (this.platform.is('ios')) {
        this.browserOption = "clearcache=yes,location=yes,toolbar=no,hidden=yes";
      }
      if (this.platform.is('mobileweb')) {
        console.log("running in a browser on mobile!");
      }
    });
//   this.idtt = "https://idsrvclone.fitnessforce.com";
  // this.idtt = "https://idsrvpp.fitnessforce.com";
   this.idtt =  identityURL ;
   //  this.idtt= "https://idsrvleej.fitnessforce.com"

    this.authurl = this.idtt + "/connect/authorize";
    this.tokenurl = this.idtt + "/connect/token";
  }

  authorize(memberid, centerid, companyid, otpid, clientID, otpcode) {
    console.log(this.platform,'platform')
    this.languageService.getlangg().then((vall) => {
      this.IsoCode = vall;
    // this.storage.get("langSelect").then((val) => {
    //   this.IsoCode = val;
    var strData = {
      memberdata: null,
      otpcode: otpcode,
      memberid: memberid,
      centerid: centerid,
      companyid: companyid,
      otpid: otpid,
      isocode: this.IsoCode || "en-US"
    }
    const guid = uuid.v4();
    var sSdata = "&mdata='null" + "&memberid=" + String(memberid) + "&otpid=" + String(otpid) + "&otpcode=" + String(otpcode) + "&isocode=" + String(this.IsoCode || 'en-US') + "&centerid=" + String(centerid) + "&companyid=" + String(companyid);
    sSdata = String(sSdata);
    console.log(sSdata, "sSdata");
    const callbackUrl = `${location.protocol}//${location.host}/call-back-url`;
    let query: any;
    let browser: any;
    let Client: any;
    if(this.platform.is('cordova')) { 
      Client = 'B2C';
      query = '?client_id=' + Client + '&scope=openid AccessControlV3 FFWebAPIV2 offline_access&response_type=code id_token token&redirect_uri=' + this.idtt + '/callback&nonce=1234&' + sSdata;
      browser = this.iab.create(this.authurl + query, '_blank', this.browserOption);
      browser.hide();
      browser.on('loadstart').subscribe((e) => {
        if ((e.url + "").startsWith(this.idtt + "/callback")) {
          let code = "";
          let url = e.url;
          let arr = url.split('#code=');
          if (arr) {
            if (arr.length > 0) {
              if (arr[1]) {
                code = arr[1].substr(0, arr[1].indexOf('&'));
              }
            }
          }
          let error = url.split('error=');
          if (error) {
            if (error.length > 0) {
              if (error[1]) {
                if (error[1].startsWith("invalid")) {
                  this.events.publish('tokengennFailed', Date.now());
                  browser.close();
                }
              }
            }
          }
          if (code) {
            if (code.length > 0) {
              browser.close();
              this.generatetoken(Client, code)
            }
          }
        }
      });

    }else{     
      query = '?client_id=DemocentralMemberPortal&scope=openid AccessControlV3 FFWebAPIV2 offline_access&response_type=code id_token token&redirect_uri=' + callbackUrl + '&nonce=1234&' + sSdata + '&guid=' + guid + '';
      window.location.href = this.authurl + query;
      window.document.body.style.display = "none";
      console.log(this.authurl + query)
    }
  });

}

  authorizeMember(centerid, firstname, lastname, emailId, gender, dob, companyid, otpid, clientID, mobileno, otpcode, dialcode) {
    // this.storage.get("langSelect").then((val) => {
    //   this.IsoCode = val;
      this.languageService.getlangg().then((vall) => {
        this.IsoCode = vall;
      let obj =
      {
        centerid: centerid,
        firstName: firstname,
        lastName: lastname,
        gender: gender,
        emailAddress: emailId,
        mobileno: mobileno,
        dialcode: dialcode
      }
      var SrData = "&firstName=" + String(firstname || '') + '&lastName=' + String(lastname || '') + '&gender=' + String(gender || '') + '&dob=' + String(dob) + '&emailAddress=' + String(emailId || '') + '&mobileno=' + String(mobileno || '') + '&dialcode=' + String(dialcode || '') + "&memberid=" + String(0) + "&otpid=" + String(otpid) + "&otpcode=" + String(otpcode) + "&isocode=" + String(this.IsoCode || 'en-US') + "&centerid=" + String(centerid) + "&companyid=" + String(companyid) + "&trackMode=mobileapp";
      const callbackUrl = `${location.protocol}//${location.host}/call-back-url`;

      let query: any;
      let browser: any;
      let Client: string = 'B2C';


    
      if (this.platform.is('desktop') || this.platform.is('pwa')) {
 
      }


      if(this.platform.is('cordova')) {



        query = '?client_id=' + Client + '&scope=openid AccessControlV3 FFWebAPIV2 offline_access&response_type=code id_token token&redirect_uri=' + this.idtt + '/callback&nonce=1234' + SrData;
        browser = this.iab.create(this.authurl + query, '_blank', this.browserOption);
        browser.hide();
        let params = new URLSearchParams(location.search);
        params.get('callback');
        browser.executeScript({ code: "document.getElementById('code').innerText" }).then((value) => {
          console.log(value, "value");
        });

        browser.on('loadstart').subscribe((e) => {
          console.log(e.url, "e.url");
          if ((e.url + "").startsWith(this.idtt + "/callback")) {
            let code;
            let url = e.url;
            let arr = url.split('#code=');
            let error = url.split('error=');
            if (arr) {
              if (arr[1]) {
                code = arr[1].substr(0, arr[1].indexOf('&'));
                console.log(code);
              }
            }
            if (code) {
              if (code.length > 0) {
                console.log("got code", code);
                browser.close();
                this.generatetoken(Client, code)
              }
            }
            if (error) {
              if (error.length > 0) {
                if (error[1]) {
                  if (error[1].startsWith("invalid")) {
                    this.events.publish('tokengennFailed', Date.now());
                    browser.close();
                  }
                }
              }
            }
          }
        });
      }
      else{
        let nocache = "&nocache=" + (new Date()).getTime();
        query = '?client_id=DemocentralMemberPortal&scope=openid AccessControlV3 FFWebAPIV2 offline_access&response_type=code id_token token&redirect_uri=' + callbackUrl + '&nonce=1234' + SrData;
         window.location.href = this.authurl + query + nocache;
         // window.document.body.style.display = "none";
      }

    });
  }
  generatetoken(clientID, code) {
    let headers = new Headers(
      {
        'Content-Type': 'application/x-www-form-urlencoded'
      });
    let options = new RequestOptions({ headers: headers });
    let postData = "client_id=" + clientID + "&client_secret=" + "secret" + "&grant_type=" + "authorization_code" + "&redirect_uri=" + this.idtt + "/callback" + "&code=" + code;
    console.log(postData, "posting this data")
    this.http.post(this.tokenurl, postData, options).subscribe(data => {
      console.log("After post call", data)
      let tokenObj = JSON.parse(data['_body']);
      let inStorage = {
        receivedToken: tokenObj,
        timestamp: moment().format("YYYY-MM-DD HH:mm ss SSS"),
        clientID:clientID
      }
      this.storage.set('authtoken', inStorage).then(() => {
        this.storage.set('tocken', 'Bearer ' + String(tokenObj.access_token)).then(() => {
          this.events.publish('tokengenn', Date.now());
          console.log("saving in db authtoken", tokenObj)
        });
      });
    }
    );
    console.log(this.storage.get('authtoken'), "itendentityCHit");
  }
  refreshtoken() {
    if (!this.platform.is('cordova')){
      return;
    }
    let promise = new Promise((resolve, reject) => {
      this.storage.get('authtoken').then((val) => {
        if (val) {
          let refresh_token_value = val.receivedToken.refresh_token;
          let clientID = val.clientID;
          let headers = new Headers(
            {
              'Content-Type': 'application/x-www-form-urlencoded'
            });

          let options = new RequestOptions({ headers: headers });

          let postData =
            "client_id=" + clientID +
            "&client_secret=" + "secret" +
            "&grant_type=" + "refresh_token" +
            "&redirect_uri=" + this.idtt + "/callback" +
            "&refresh_token=" + refresh_token_value;

          this.http.post(this.tokenurl, postData, options).subscribe(data => {
            console.log(JSON.stringify(data));
            let tempp = JSON.parse(data['_body'])

            let tokenObj = {
              receivedToken: tempp,
              timestamp: moment().format("YYYY-MM-DD HH:mm ss SSS"),
              clientID:clientID
            }

            this.storage.set('authtoken', tokenObj).then(() => {
              this.storage.set('tocken', 'Bearer ' + String(tempp.access_token)).then(() => {
                resolve(true);
              });
            });
          },
            error => {
              let tempp = JSON.parse(error['_body'])
              if (tempp.error) {
                console.log("rfresh failss")
                this.clearData();
                this.storage.keys().then(a=>{
                  a.map((f,indx)=>{
                    if(f != 'langSelect' && f != 'SELECTED_LANGUAGE' && f != 'SELECTED_DIR'  && f != 'SelectLang' && f != 'OnboardingShown')
                    {
                      this.storage.remove(f)
                      if(indx == a.length-1)
                      {
                        this.router.navigate(['/login']);
                      }
                    }
                  });
                  this.storage.remove('USER_STORE');
                  this.storage.remove('memberDetailsForOtp')
                  this.storage.remove('tenantDetailsForOtp')
                  this.storage.remove('theme_type')
                  // this.events.unsubscribe('brandLogo');
                })
                this.router.navigate(['/login']);
                resolve(false);
                this.logout.clearData();
              }
            }
          );
        }
      });
      console.log("refresh");
    });
    return promise;
  }

  clearData() {
    this.storage.remove('appointmentdetails')
    this.storage.remove('PRODUCTTERMSFLAG')
    this.storage.remove('TERMSARRAY')
    this.storage.remove("ProductBill")
    this.storage.remove('savedSignature')
    this.storage.remove('feedbackdetails')
    this.storage.remove('cameraImage')
    this.storage.remove('uploadeddocs')
    this.storage.remove('recentLocations')
    this.storage.remove('authtoken')
    this.storage.remove('WaiverSignedIn')
    this.storage.remove('PURPOSESELECTED--BUY JOIN')
    this.storage.remove('tocken')
    this.storage.remove("refreshTenantNameDate");
    this.storage.remove('PaymentOption')
    this.storage.remove('appCurrency')
    this.storage.remove('CARTITEM')
    this.storage.remove('CACHE_STORE')
    this.storage.remove('ReapplymemOption');
    this.storage.remove('Reapplyduration');
    this.storage.remove('Reapplyfreeze');
    this.storage.remove('Reapplyprod');
    this.storage.remove('Reapplysession');
    this.storage.remove('CountryData')
    this.storage.remove('DefaultCountryDialCode')
    this.storage.remove('DefaultCountryCode')
    this.storage.remove("MemberShipType");
    this.storage.remove("MemberShipTypeSubOptionHomeOrPassport");
    this.storage.remove("MemberShipTypeSubOptionDuration");
    this.storage.remove("MemberShipTypeSubOptionFreeze");
    this.storage.remove('loggedIn');
    this.storage.remove('USER_STORE');
    this.storage.remove('NewMember');
    this.storage.remove('OTP_VERIFIED');
    this.router.navigate(['/login']);
  }
}

